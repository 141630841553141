
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import { IsPC } from '@/utils/bank.js';


import apps from '@/views/apps.js'

@Component({
  components: {
      TmPc: () => import('./pc/'),
      TmMobile: () => import('./mobile/'),
   },
})
export default class selectTemplate extends Vue {
  
}
