
import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import Toastrs from "@/utils/Toastrs";
import apps from "@/views/apps.js";

@Component({})
export default class Index extends Vue {
  public mounted() {
    let appid: any = this.$route.query.appid || localStorage.getItem("appid");
    let redirect_uri: any =
      this.$route.query.redirect_uri || localStorage.getItem("redirect_uri");

    if (!appid) {
      Toastrs.error("缺少参数 appid ");
      return;
    }
    if (!redirect_uri) {
      Toastrs.error("缺少参数 redirect_uri ");
      return;
    }

    let AppData = (apps as any)[appid] || (apps as any)['0'];

    let channels: any =
      this.$route.query.channels || localStorage.getItem("channels");
    let login_url: any = localStorage.getItem("login_url") || window.location.href;
    let user_type: any = 2;
    let template = 'TmMobile'

 
    if (AppData) {
      user_type = AppData.user_type;
      template = AppData.template
      if (!channels) {
        channels = AppData.channels;
      }
    }

    this.$store.dispatch("setAppid", {
      appid,
      channels,
      redirect_uri,
      login_url,
      user_type,
      template
    });
  }
}
