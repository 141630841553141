function Toastr() {
	let templet = document.createElement('div')
		templet.style.position = 'fixed';
		templet.style.top =  0;
		templet.style.left =  0;
		templet.style.right = 0;
		templet.style.bottom = 0;
		templet.style.backgroundColor = 'rgba(0,0,0,0.3)';
		templet.style.textAlign = 'center';
		templet.style.zIndex = '999';
		templet.style.display = 'none';
		templet.style.padding = '8vw 4vw';


	const Obj =  {
		queue:0,
		templet:templet,

		setTpye(string, theme){
			let a = document.createElement('div')
			let b = document.createElement('div')
				b.style.display = 'inline-block';
				b.style.backgroundColor = `${theme[1]}`;
				b.style.border = `1px solid ${theme[3]}`;
				b.style.borderRadius = '5vw';
				b.style.fontSize = '4vw';
				b.style.lineHeight = '6vw';
				b.style.color = `${theme[0]}`;
				b.style.marginTop =  '45%';
				b.style.padding = '3vw 4vw';

			b.innerHTML = string
			a.appendChild(b)

			return  a

			

		},
		addLing(string, theme){
			Obj.queue++;
			if (Obj.queue) {
				templet.style.display = 'block';
			}
			let dom = Obj.setTpye(string, theme)

			templet.appendChild(dom)

			setTimeout(()=>{
				templet.removeChild(dom)
				Obj.queue--;
				if (!Obj.queue) {
					templet.style.display = 'none';
				}
			}, 1500)



		},
		msg(string){
			Obj.addLing(string, ['#606266', '#fff', 'dcdfe6'])
		},
		info(string){
			Obj.addLing(string, ['#909399', '#f4f4f5', '#d3d4d6'])
		},
		primary(string){
			Obj.addLing(string, ['#409eff', '#ecf5ff', '#b3d8ff'])
		},
		warning(string){
			Obj.addLing(string, ['#e6a23c', '#fdf6ec', '#f5dab1'])
		},
		success(string){
			Obj.addLing(string, ['#67c23a', '#f0f9eb', '#c2e7b0'])
		},
		error(string){
			Obj.addLing(string, ['#f56c6c', '#fef0f0', '#fbc4c4'])
		},
		customize(string, opting){
			Obj.addLing(string, [opting.color, opting.background, opting.border])
		},
		init(){
			document.body.appendChild(templet)
		}
	}

	Obj.init()

	return Obj
}


export default Toastr