const PC = 'TmPc'
const MOBILE = 'TmMobile'
const empl = 'empl'
const user = 'user'

const apps = {
    303:{
        template: PC,
        user_type:1,
        channels: 'sms'
    },
    317:{
        template: PC,
        user_type:1,
        channels: 'sms'
    },
    313:{
        template: MOBILE,
        user_type:1,
        channels: 'sms'
    },
    316:{
        template: MOBILE,
        user_type: 2,
        channels: 'sms'
    },
    0:{
        template: MOBILE,
        user_type: 2,
        channels: 'sms'
    },

}

export default apps