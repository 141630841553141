import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    appid: '0',
    channels: '',
    redirect_uri: '',
    login_url: '',
    user_type: '1',
    template: ''
  },
  mutations: {
    SET_APPID(state: any, { appid, channels, redirect_uri, login_url, user_type, template }) {
      state.appid = appid;
      state.channels = channels;
      state.redirect_uri = redirect_uri;
      state.login_url = login_url;
      state.user_type = user_type;
      state.template = template;

      localStorage.setItem("appid", appid);
      localStorage.setItem("channels", channels);
      localStorage.setItem("redirect_uri", redirect_uri);
      localStorage.setItem("login_url", login_url);
      localStorage.setItem("user_type", user_type);
      localStorage.setItem("template", template);
      
    },
  },
  actions: {
    setAppid: ({ commit }, { appid, channels, redirect_uri, login_url, user_type, template }) => {
      commit('SET_APPID', {appid, channels, redirect_uri, login_url, user_type, template});
    },
    removelocalStorage(){
      localStorage.removeItem('appid')
      localStorage.removeItem('channels')
      localStorage.removeItem('redirect_uri')
      localStorage.removeItem('login_url')
      localStorage.removeItem('user_type')
      localStorage.removeItem('template')
    }
  },
  modules: {
  },
});
