import toastr from 'toastr';
import mToastr from './mToastr'


import { IsPC } from '@/utils/bank.js'
const Toastr = new mToastr()
const PC = {
    msg: toastr.show,
    error: toastr.error,
    success: toastr.success,
    warning: toastr.warning
}

const Mobile = {
    msg: Toastr.msg,
    error: Toastr.error,
    success: Toastr.success,
    warning: Toastr.warning
}



export default IsPC() ? PC : Mobile